<template>
  <div>
    <div class="columns has-text-right">
      <div class="column is-narrow mt-2">
        <span v-if="!this.hideUpdate==true && !this.advanceSearch == true">Publicaciones del año: </span>

      </div>
      <div class="column is-3">
        <b-field v-if="!this.hideUpdate==true && !this.advanceSearch == true">
          <multiselect v-model="year"
                       :options="years"
                       @input="updatePublications"
                       placeholder="Seleccione año"
                       selectLabel="Presione para seleccionar"
                       selectedLabel="Seleccionado"
                       deselectLabel="No se puede deseleccionar"
                       :allow-empty="false">
            <template v-slot:noOptions>
              No existen datos
            </template>
            <span slot="noResult">
                        No se encontraron elementos.
                    </span>
          </multiselect>
        </b-field>
      </div>
      <div class="column">
        <b-switch v-model="cardLayout">Formato tarjeta (sin filtros ni búsqueda)
             <b-tooltip
                 label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                 multilined
                 position="is-left">
                  <b-icon
                      pack="fas"
                      icon="info-circle"
                      type="is-info">
                  </b-icon>
              </b-tooltip></b-switch>
      </div>
    </div>
    <b-table
        :data="publicaciones"
        :paginated=true
        per-page=10
        striped
        hoverable
        :card-layout="cardLayout"
        :current-page="currentPage"
        :pagination-simple=false
        pagination-position="bottom"
        default-sort-direction="desc"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort="publication.publication_date"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Página actual"
        :opened-detailed="defaultOpenedDetails"
        detailed
        detail-key="title"
        @details-open="(row) => $buefy.toast.open(`Expanded ${row.title}`)"
        :show-detail-icon="showDetailIcon"
        >
      <b-table-column
          field= 'title'
          label= 'Título'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.title }}
        </template>
      </b-table-column>

<!--      <b-table-column
          field= 'publication_type'
          label= 'Tipo de Publicacion'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.publication_type }}
        </template>
      </b-table-column>-->

      <b-table-column
          field= 'magazine'
          label= 'Revista'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.magazine }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'publication_date'
          label= 'Fecha de publicación'
          searchable
          :custom-search="searchPublicationDate"
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.publication_date._isValid ? new Date(props.row.publication_date).toLocaleDateString("es-CL"): '' }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'is_wos'
          label= 'Es Wos'
          sortable
          v-slot="props"
      >
        <b-checkbox disabled v-model="props.row.is_wos">
        </b-checkbox>
      </b-table-column>
      <b-table-column
          field= 'is_scielo'
          label= 'Es Scielo'
          sortable
          v-slot="props"
      >
        <b-checkbox disabled v-model="props.row.is_scielo">
        </b-checkbox>
      </b-table-column>
      <b-table-column
          field= 'is_scopus'
          label= 'Es Scopus'
          sortable
          v-slot="props"
      >
        <b-checkbox disabled v-model="props.row.is_scopus">
        </b-checkbox>
      </b-table-column>
      <b-table-column
          v-show="cargoPrincipal.name == 'ANALISTA I+D FING' || cargoPrincipal.name == 'SUPERADMIN'"
          label="Acciones"
          width="140px"
          v-slot="props">
          <b-tooltip
              v-if="props.row.can_show"
              label="Mostrar en detalle la información de la publicación"
              :active="true">
              <b-button
                  pack="fas"
                  icon-right="eye"
                  type="is-info"
                  tag="a"
                  :href="/publications/ + props.row.id"
                  target="_blank"/>
          </b-tooltip>

          <b-tooltip
              v-if="props.row.can_edit"
              label="Editar la información de la publicación"
              :active="true">
              <b-button
                  pack="fas"
                  icon-right="edit"
                  type="is-warning"
                  @click="isComponentModalActive = true;
                  selectedPublicationId = props.row.id"/>
          </b-tooltip>


          <b-tooltip
              v-if="props.row.can_null"
              label="Anular registro de la publicación"
              :active="true">
              <b-button
                  pack="fas"
                  icon-right="ban"
                  type="is-danger"
                  @click="execNullifyModal(props.row.id, props.row)"/>
          </b-tooltip>

      </b-table-column>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>Autores: </strong>
                <span>{{props.row.participants_references}}</span>
              </p>
            </div>
          </div>
        </article>
      </template>

      <template slot="empty" slot-scope="props">
        <div class="columns is-centered">
          <div class="column is-6 is-centered has-text-centered">
            <b-message v-if="advanceSearch != true" type="is-danger">
              No se encontraron resultados.
            </b-message>
            <b-message v-else type="is-danger">
              No se encontraron resultados para los criterios de búsqueda seleccionados.
            </b-message>
          </div>
        </div>
      </template>
    </b-table>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    <b-modal :active="isComponentModalActive" width="90%" :can-cancel="false" aria-modal>
      <template #default="props">
        <NewPublicationForm modal-cancel="true" :cargo-principal="cargoPrincipal" :user-id="personId" modal-header="Editar Publicación" :fromAcademic="true"
                          query-type="update" :publication-id="selectedPublicationId" @close="isComponentModalActive = false" @refresh="updatePublications"/>
      </template>
    </b-modal>

  </div>
</template>
<script>
import {searchDate} from "../../packs/utilities";
import NewPublicationForm from "./NewPublicationForm";
import axios from "axios";
export default {
  components: {
    NewPublicationForm,
  },
  props:[
      'publicaciones','hideUpdate','cargoPrincipal','advanceSearch', 'personId'
  ],
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      year: new Date().getFullYear(),
      years:[],
      isNullifyActive: false,
      isComponentModalActive: false,
      selectedPublicationId: null,
    }
  },
  created(){
    this.years = this.generateArrayOfYears();
  },
  methods:{
    generateArrayOfYears() {
      var max = new Date().getFullYear()
      var min = 1980
      var years = []
      years.push("Todo")
      for (var i = max; i >= min; i--) {
        years.push(i)
      }
      return years
    },
    updatePublications(){
      this.$emit('updatePublications',this.year)
    },
    searchPublicationDate(row,input){
      return searchDate(row.publication_date,input)
    },
    execNullifyModal(id, row){
      this.$buefy.dialog.confirm({
        title: 'Anular Publicación',
        message: '¿Está seguro que desea <b>anular</b> la publicación? Esta acción lo dejará inválido.<br>',
        confirmText: 'Anular Publicación',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .delete("/publications/" + id + "/nullify_publication.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Publicación anulada correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })

                var index = this.publicaciones.indexOf(row)
                this.publicaciones.splice(index, 1)
                this.isLoading = false;
                this.isNullifyActive = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Publicación no pudo ser anulada</b><br>'+error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isNullifyActive = false;
          })
        }
      })
    },

  },
  watch:{
    isNullifyActive: function () {
      if (!this.isNullifyActive) {
        this.updatePublications()
      }
    },
  },
  computed:{

  }
}
</script>

