<template>
  <div>

    <div class="columns">
      <div class="column is-narrow mt-2">
        <span v-if="!this.hideUpdate==true && !this.advanceSearch == true">Patentes presentadas año: </span>
      </div>
      <div class="column is-3">
        <b-field v-if="!this.hideUpdate==true && !this.advanceSearch == true">
          <multiselect v-model="year"
                       :options="years"
                       @input="updatePatents"
                       placeholder="Seleccione año"
                       selectLabel="Presione para seleccionar"
                       selectedLabel="Seleccionado"
                       deselectLabel="No se puede deseleccionar"
                       :allow-empty="false">
            <template v-slot:noOptions>
              No existen datos
            </template>
            <span slot="noResult">
                        No se encontraron elementos.
                    </span>
          </multiselect>
        </b-field>
      </div>

      <div class="column has-text-right">
        <b-field>
          <b-switch v-model="cardLayout">
          <span>
            Formato tarjeta (sin filtros ni búsqueda)
             <b-tooltip
                 label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                 multilined
                 position="is-left">
                  <b-icon
                      pack="fas"
                      icon="info-circle"
                      type="is-info">
                  </b-icon>
              </b-tooltip>
          </span>
          </b-switch>
        </b-field>

      </div>
    </div>
    <b-table
        :data="patentes"
        :paginated=true
        per-page=10
        striped
        hoverable
        :current-page="currentPage"
        :card-layout="cardLayout"
        :pagination-simple=false
        pagination-position="bottom"
        default-sort-direction="desc"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort="last_patent_state_date"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Página actual"
        :opened-detailed="defaultOpenedDetails"
        detailed
        detail-key="id"
        :show-detail-icon="showDetailIcon">
      <b-table-column
          field= 'title'
          label= 'Título'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.title }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'patent_type'
          label= 'Tipo de Protección'
          searchable
          sortable>
          <template
              slot="searchable"
              slot-scope="props">
            <b-input
                v-model="props.filters[props.column.field]"
                icon="search"
                size="is-small" />
          </template>
          <template v-slot="props">
            {{ props.row.patent_type }}
          </template>
      </b-table-column>
      <b-table-column
          field= 'protection_code'
          label= 'Código de Protección'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.protection_code }}
        </template>
      </b-table-column>

      <b-table-column
          field= 'nationality'
          label= 'Nacionalidad'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{props.row.nationality }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'presentation_date'
          label= 'Fecha de presentación'
          searchable
          :custom-search="searchPresentationDate"
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{  props.row.presentation_date._isValid ? new Date(props.row.presentation_date).toLocaleDateString("es-CL") : '' }}
        </template>
      </b-table-column>

      <b-table-column
          field= 'last_patent_state'
          label= 'Último estado'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{props.row.last_patent_state }}
        </template>
      </b-table-column>

      <b-table-column
          field= 'last_patent_state_date'
          label= 'Fecha último estado'
          searchable
          :custom-search="searchLastPatentStateDate"
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{  props.row.last_patent_state_date._isValid ? new Date(props.row.last_patent_state_date).toLocaleDateString("es-CL") : '' }}
        </template>
      </b-table-column>
      <b-table-column
          v-show="cargoPrincipal.name == 'ANALISTA I+D FING' || cargoPrincipal.name == 'SUPERADMIN'"
          label="Acciones"
          width="140px"
          v-slot="props">
          <b-tooltip
              label="Mostrar en detalle la información de la patente"
              :active="true"
              v-if="props.row.can_show">
              <b-button pack="fas" icon-right="eye" type="is-info" tag="a" :href="/patents/ + props.row.id" target="_blank"/>
          </b-tooltip>
          <b-tooltip
              label="Editar la información de la patente"
              :active="true"
              v-if="props.row.can_edit">
              <b-button pack="fas" icon-right="edit" type="is-warning" @click="isComponentModalActive = true; selectedPatentId = props.row.id"/>
          </b-tooltip>
          <b-tooltip
              label="Anular registro la patente"
              :active="true"
              v-if="props.row.can_null">
              <b-button pack="fas" icon-right="ban" type="is-danger" @click="execNullifyModal(props.row.id, props.row)"/>
          </b-tooltip>
      </b-table-column>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>Participantes: </strong>
                <span v-for="participant in props.row.person_patents_attributes" >
                  <a v-if="participant.person_url!=null" :href="participant.person_url">{{ participant.person_name }}</a>
                  <span v-else>{{ participant.person_name }}</span>
                  <span v-if="props.row.person_patents_attributes.length>1 && participant!=props.row.person_patents_attributes.slice(-1)[0]">, </span>
                </span>
              </p>
            </div>
          </div>
        </article>
      </template>
      <template slot="empty" slot-scope="props">
        <div class="columns is-centered">
          <div class="column is-6 is-centered has-text-centered">
            <b-message v-if="advanceSearch != true" type="is-danger">
              No se encontraron resultados.
            </b-message>
            <b-message v-else type="is-danger">
              No se encontraron resultados para los criterios de búsqueda seleccionados.
            </b-message>
          </div>
        </div>
      </template>
    </b-table>

    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />

    <b-modal
        :active="isComponentModalActive" width="90%" :can-cancel="false" aria-modal>
      <template>
        <NewPatentFormVue modal-cancel="true" :cargo-principal="cargoPrincipal" :user-id="personId" modal-header="Editar Patente" :fromAcademic="true"
                             query-type="update" :patent-id="selectedPatentId" @close="isComponentModalActive = false" @refresh="updatePatents()"/>
      </template>
    </b-modal>

  </div>
</template>
<script>
import {searchDate} from "../../packs/utilities"
import axios from "axios"
import NewPatentFormVue from './NewPatentForm.vue'
export default {
  props:[
      'patentes','personId','hideUpdate','cargoPrincipal', 'advanceSearch'
  ],
  components: {
    NewPatentFormVue,
  },
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon:true,
      years:[],
      year:null,
      isNullifyActive: false,
      isComponentModalActive: false,
      selectedPatentId: null,
    }
  },
  created(){
    this.years = this.generateArrayOfYears();
    this.year = new Date().getFullYear();
  },

  methods:{
    generateArrayOfYears() {
      var max = new Date().getFullYear()
      var min = 1980
      var years = []
      years.push("Todo")
      for (var i = max; i >= min; i--) {
        years.push(i)
      }
      return years
    },

    updatePatents(){
      this.$emit('updatePatents',this.year)
    },
    searchLastPatentStateDate(row,input){
      return searchDate(row.last_patent_state_date,input)
    },
    searchPresentationDate(row,input){
      return searchDate(row.presentation_date,input)
    },
    execNullifyModal(id, row){
      this.$buefy.dialog.confirm({
        title: 'Anular Patente',
        message: '¿Está seguro que desea <b>anular</b> la patente? Esta acción lo dejará inválido.<br>',
        confirmText: 'Anular Patente',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .delete("/patents/" + id + "/nullify_patent.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Patente anulada correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })

                var index = this.patentes.indexOf(row)
                this.patentes.splice(index, 1)
                this.isLoading = false;
                this.isNullifyActive = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Patente no pudo ser anulada</b><br>'+error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isNullifyActive = false;
          })
        }
      })
    },


  },
  watch:{
    isNullifyActive: function () {
      if (!this.isNullifyActive) {
        this.updatePatents()
      }
    },
  },
  computed:{

  }
}
</script>

