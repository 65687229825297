<template>
  <div>
    <div class="columns">
      <div class="column is-narrow mt-2">
        <span v-if="(!this.hideUpdate==true && !showPostulated)&& !this.advanceSearch == true">Proyectos en curso año: </span>
        <span v-if="!this.hideUpdate==true && showPostulated">Proyectos postulados año: </span>

      </div>
      <div class="column is-3">
        <b-field v-if="!this.hideUpdate==true && !this.advanceSearch == true">
          <multiselect v-model="year"
                       :options="years"
                       @input="updateProjects"
                       placeholder="Seleccione año"
                       selectLabel="Presione para seleccionar"
                       selectedLabel="Seleccionado"
                       deselectLabel="No se puede deseleccionar"
                       :allow-empty="false">
            <template v-slot:noOptions>
              No existen datos
            </template>
            <span slot="noResult">
                No se encontraron elementos.
            </span>
          </multiselect>
        </b-field>
      </div>
      <div class="column has-text-right">
        <b-field>
          <b-switch v-model="cardLayout">Formato tarjeta (sin filtros ni búsqueda)
            <b-tooltip
                label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                multilined
                position="is-left">
              <b-icon
                  pack="fas"
                  icon="info-circle"
                  type="is-info">
              </b-icon>
            </b-tooltip></b-switch>
        </b-field>

      </div>
    </div>
    <b-table
        :data="proyectos"
        :paginated=true
        :card-layout="cardLayout"
        per-page=10
        striped
        hoverable
        :current-page="currentPage"
        :pagination-simple=false
        pagination-position="bottom"
        default-sort-direction="asc"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort="project.name"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Página actual"
        :opened-detailed="defaultOpenedDetails"
        detailed
        detail-key="code"
        :show-detail-icon="showDetailIcon"
        >
      <b-table-column
          field= 'name'
          label= 'Nombre'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.name }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'project_type'
          label= 'Tipo de Proyecto'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.project_type }}
        </template>
      </b-table-column>
      <b-table-column
          field= 'code'
          label= 'Código'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.code }}
        </template>
      </b-table-column>
      <b-table-column
          v-if="!showPostulated"
          field= 'award_year'
          label= 'Año de adjudicación'
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.award_year }}
        </template>
      </b-table-column>
      <b-table-column
          v-if="!showPostulated"
          field= 'start_date'
          label= 'Fecha Inicio'
          searchable
          :custom-search="searchInitDate"
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL")  : "" }}
        </template>
      </b-table-column>
      <b-table-column
          v-if="!showPostulated"
          field= 'end_date'
          label= 'Fecha Término'
          searchable
          :custom-search="searchEndDate"
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.end_date._isValid ? new Date(props.row.end_date).toLocaleDateString("es-CL") : "" }}
        </template>
      </b-table-column>
      <b-table-column
          v-if="showPostulated"
          field= 'postulation_date'
          label= 'Fecha de Postulación'
          searchable
          :custom-search="searchPostulationDate"
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.postulation_date._isValid ? new Date(props.row.postulation_date).toLocaleDateString("es-CL") : "" }}
        </template>
      </b-table-column>
      <b-table-column
          v-show="cargoPrincipal.name == 'ANALISTA I+D FING' || cargoPrincipal.name == 'SUPERADMIN'"
          label="Acciones"
          v-slot="props"
          width="140px">
          <b-tooltip
              v-if="props.row.can_show"
              label="Mostrar en detalle la información del proyecto"
              :active="true">
            <b-button pack="fas" icon-right="eye" type="is-info" tag="a" :href=" props.row.is_postulated ? '/projects/postulated/' + props.row.id : '/projects/' + props.row.id" target="_blank"/>
          </b-tooltip>
          <b-tooltip
              v-if="props.row.can_edit"
              label="Editar en detalle la información del proyecto"
              :active="true">
              <b-button pack="fas" icon-right="edit" type="is-warning" @click="isComponentModalActive = true; selectedProjectId = props.row.id; selectedProject = props.row"/>
          </b-tooltip>
        <b-tooltip
            v-if="props.row.can_null"
            label="Anular registro del proyecto"
            :active="true">
            <b-button pack="fas" icon-right="ban" type="is-danger" @click="execNullifyModal(props.row.id, props.row)"/>
        </b-tooltip>
      </b-table-column>
      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>Participantes: </strong>
                <span v-for="(participant, index) in props.row.participants" :key="index">
                  <a v-if="participant.url!=null" :href="participant.url">{{ participant.name }}</a>
                  <span v-else>{{ participant.name }}</span>
                  <span v-if="props.row.participants.length>1 && participant!=props.row.participants.slice(-1)[0]">, </span>
                </span>
              </p>
            </div>
          </div>
        </article>
      </template>
      <template slot="empty" slot-scope="props">
        <div class="columns is-centered">
          <div class="column is-6 is-centered has-text-centered">
            <b-message v-if="advanceSearch != true" type="is-danger">
              No se encontraron resultados.
            </b-message>
            <b-message v-else type="is-danger">
              No se encontraron resultados para los criterios de búsqueda seleccionados.
            </b-message>
          </div>
        </div>
      </template>
    </b-table>


    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />

    <b-modal :active="isComponentModalActive" width="90%" :can-cancel="false" aria-modal>
      <template>
        <NewProjectForm modal-cancel="true" :cargo-principal="cargoPrincipal" modal-header="Editar Proyecto" :fromAcademic="true"
                          query-type="update" :project-id="selectedProjectId" :project="selectedProject" @close="isComponentModalActive = false" @refresh="updateProjects()"/>
      </template>
    </b-modal>

  </div>
</template>
<script>
import {searchDate} from "../../packs/utilities"
import axios from "axios"
import NewProjectForm from "./NewProjectForm.vue"
export default {
  props:[
      'proyectos','unitId','hideUpdate','showPostulated', 'cargoPrincipal', 'advanceSearch'
  ],
  components: {
    NewProjectForm,
  },
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      years:[],
      year:null,
      isNullifyActive: false,
      isComponentModalActive: false,
      selectedProjectId: null,
      selectedProject: {},
    }
  },
  created(){
    this.years = this.generateArrayOfYears();
    this.year = new Date().getFullYear();

  },
  methods:{
    generateArrayOfYears() {
      var max = new Date().getFullYear()
      var min = 1980
      var years = []
      years.push("Todo")
      for (var i = max; i >= min; i--) {
        years.push(i)
      }
      return years
    },

    updateProjects(){
      this.$emit('updateProjects',this.year)
    },
    searchEndDate(row,input){
      return searchDate(row.end_date,input)
    },
    searchInitDate(row,input){
      return searchDate(row.start_date,input)
    },
    searchPostulationDate(row,input){
      return searchDate(row.start_date,input)
    },
    execNullifyModal(project_id, row){
      this.$buefy.dialog.confirm({
        title: 'Anular proyecto',
        message: '¿Está seguro que desea <b>anular</b> el proyecto? Esta acción lo dejará inválido.<br>',
        confirmText: 'Anular Proyecto',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .delete("/projects/" + project_id + "/nullify_project.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Proyecto anulado correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })

                var index = this.proyectos.indexOf(row)
                this.proyectos.splice(index, 1)
                this.isLoading = false;
                this.isNullifyActive = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Proyecto no pudo ser anulado</b><br>'+error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isNullifyActive = false;
          })
        }
      })
    },

  },
  watch:{
    isNullifyActive: function () {
      if (!this.isNullifyActive) {
        this.updateProjects()
      }
    },
  },
  computed:{

  }
}
</script>

